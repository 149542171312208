import { Counter } from './comonents/counter';

function App() {
  return (
    <div className="App">
      <header>
        <a href="/"><b>Gib Geld: Abrechnung für Camps</b></a>
        <a href="mailto:jimbimlover69@drunkt.de">Contact your creditor</a>
      </header>
      <main>
        <Counter />
      </main>
    </div>
  );
}

export default App;
