import { Component } from "react";
import intervalToDuration from "date-fns/intervalToDuration";
import sub from "date-fns/sub";
import format from "date-fns/format";
import toDate from "date-fns/toDate";
import { formatDuration, parseISO } from "date-fns";
import moment from "moment";

export class Counter extends Component {

  constructor() {
    super()
    this.state = {
      goalReached: false
    }
  }

  componentDidMount() {
    this.counter = setInterval(this.setDuration.bind(this), 1);
  }


  setDuration() {
    const now = moment()
    const end = moment(" 30 December 2021, 13:13:13");
    const diff = end.diff(now)
    if (diff > 0) {
      const duration = moment.duration(diff);

      this.setState({
        duration: duration,
        goalReached: false
      })
    } else {
      this.setState({
        goalReached: true
      })
      clearInterval(this.counter)
    }
  }

  render() {

    if (this.state?.goalReached) {
      return <div>
        <div className="img-wrapper">
          <img src="/assets/eko-fresh.jpeg" />
        </div>
        <div class="text">
          <h1>Macht Gib Die Geld! Wo ist die verdammtes Geld, Lebowski?<sup>*</sup></h1>
          <small><sup>*</sup>12,5 Monate 0% Finanzierung möglich.</small>
        </div>
      </div>;
    }

    return !this.state?.duration
      ? <div > F ** k</div >
      :
      <div className="count-down">
        <h1>Wann muss ich die liquide sein?</h1>
        <div className="data">
          <span>{this.state.duration.months()} Monate</span>
          <span>{this.state.duration.days()} Tage</span>
          <span>{this.state.duration.hours()} Stunden</span>
          <span>{this.state.duration.minutes()} min</span>
          <span>{this.state.duration.seconds()} s</span>
          <span>{this.state.duration.milliseconds()} ms</span>
        </div>
      </div>;
  }
}